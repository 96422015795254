import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext.js';
import format from 'date-fns/format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tenants } from './Tenants';
import { ToggleButton } from 'primereact/togglebutton';

const formatStr = "yyyy-MM-dd-hhmm";

export const TenantDashboard = () => {
    const [tenants, setTenants] = useState([]);
    const [tenantRecords, setTenantRecords] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [currentTenant, setCurrentTenant] = useState(null);
    const [toggleMonthWeek, setToggleMonthWeek] = useState(true);
    const [scrollHeight, setScrollHeight] = useState('80vh');
    const user = useContext(UserContext);

    useEffect(() => {
        const calculateScrollHeight = () => {
            // Adjust the height calculation as needed
            const viewportHeight = window.innerHeight - 235; // Adjust based on your layout
            setScrollHeight(`${viewportHeight}px`);
        };

        calculateScrollHeight(); 

        window.addEventListener('resize', calculateScrollHeight);

        return () => {
            window.removeEventListener('resize', calculateScrollHeight);
        };
    }, []);

    useEffect(() => {
        if (tenantRecords.length === 0 || tenants.length === 0) return;

        if (toggleMonthWeek) {
            showDays(30);
        } else {
            showDays(7);
        }
    }, [toggleMonthWeek, tenantRecords, tenants]);

    useEffect(() => {
        let isMounted = true;

        user.apiCall('tenants/records')
            .then(res => {
                if (isMounted) {
                    const fetchedTenants = res.data.tenants || [];
                    const fetchedRecords = res.data.records || [];

                    setTenants(fetchedTenants);
                    setTenantRecords(fetchedRecords);

                    let headerList = {};
                    const newData = fetchedTenants.map((t, idx) => {
                        const recordList = (fetchedRecords[idx]?.recordList || []);
                        let recordDict = {};
                        recordList.forEach((val) => {
                            const recDt = new Date(val.transactionsDate);
                            headerList[`${format(recDt, "yyyy-MM-dd")}T12:00:00`] = true;

                            const dtStr = format(recDt, "EEE M/d");
                            recordDict[dtStr] = val;
                        });
                        return {
                            ...t,
                            records: recordDict
                        };
                    });

                    const headerDts = Object.keys(headerList).sort().map(val => new Date(val));
                    setColumnHeaders(headerDts.map(valDt => format(valDt, "EEE  M/d")));
                    setTableData(newData);
                }
            })
            .then(() => {
                if (isMounted) {
                    let scrollTable = document.querySelector(".tenant-table > .p-datatable-wrapper");
                    if (scrollTable) {
                        scrollTable.scrollLeft += 9001;
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }, [user]);

    const showDays = (numDays) => {
        const recordList = tenantRecords[0]?.recordList || [];
        if (!Array.isArray(recordList)) {
            console.error('Expected recordList to be an array.');
            return;
        }

        let temp = [];

        if(!toggleMonthWeek){
            const records = tenantRecords[0]?.recordList ?? [];
            for (let e of records.slice(-1 * numDays)) {
                temp.push(format(new Date(e.transactionsDate), "EEE M/d"));
            }
            setColumnHeaders(temp);

            let newData = tenants.map((t, idx) => {
                return {
                    ...t,
                    records: buildWeeklyRecords((tenantRecords[idx]?.recordList ?? []).slice(-1 * numDays))
                };
            });
            setTableData(newData);
        }
        else{
            const records = tenantRecords[0]?.recordList ?? [];
            for (let e of records.slice(-1 * numDays)) {
                temp.push(format(new Date(e.transactionsDate), "EEE M/d"));
            }
            setColumnHeaders(temp);

            let newData = tenants.map((t, idx) => {
                return {
                    ...t,
                    records: buildWeeklyRecords((tenantRecords[idx]?.recordList ?? []).slice(-1 * numDays))
                };
            });
            setTableData(newData);
        }
    };

    const buildWeeklyRecords = (records) => {
        if (!Array.isArray(records)) {
            console.error('Expected records to be an array.');
            return {};
        }

        let rObj = {};
        for (let r of records) {
            rObj[format(new Date(r.transactionsDate), "EEE M/d")] = r;
        }
        return rObj;
    };

    const dayBodyTemplate = (rowData, dictKey) => {
        let backgroundColor = "#FFCCCB";
        let tooltipStr = "No Record Submitted";
        if (!dictKey) {
            return;
        }
        const elem = rowData.records[dictKey];
        if (elem?.lastModifiedByEmail) {
            //console.log("Number of Transactions",elem.numTransactions, "Monetary Amount", elem.monetaryAmount)
            if ((elem.numTransactions > 0) === (elem.monetaryAmount > 0)) {
                tooltipStr = "Record Submitted";
                backgroundColor = "#90EE90";
            } else {
                tooltipStr = "Record Partially Submitted";
                backgroundColor = "#FFCCCB";
            }
        }
        const targetClass = `day-tooltip-${rowData.tenantId}${format(new Date(elem?.transactionsDate || new Date()), formatStr)}`;

        return (
            <>
                <div 
                    className={targetClass}
                    data-pr-tooltip={tooltipStr} 
                    data-pr-position="top"
                    style={{ 
                        display: 'inline-block', 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: backgroundColor 
                    }}
                >
                </div>
                <Tooltip target={`.${targetClass}`} />
            </>
        );
    };

    const nameTemplate = (rowData) => {
        return (
            <>
            <Tooltip target=".button-tooltip" content="View tenant details" />
            <Button
                className="button-tooltip"
                style={{ width: "100%", background: "white", color: "blue", border: "0px", textDecoration: 'underline' }}
                label={rowData.name}
                onClick={() => setCurrentTenant(rowData)}
            />
        </>
        );                                                  
    };

    const sumTemplate = (rowData) => {
        const absCount = rowData.weekTransactions < 0.0 ? 0.0 : rowData.weekTransactions;
        const absVal = rowData.weekValue < 0.0 ? 0.0 : rowData.weekValue;
        const monOut = (Math.round(absVal * 100) / 100).toFixed(2);

        return (
            <div className="sum-template">
                <p>Monetary Amount: ${monOut}</p>
                <p>Transactions: {absCount}</p>
            </div>
        );
    };

    const activeTemplate = (rowData, props) => {
        const targetClass = `active-tooltip-${rowData.tenantId}${props.field}`;
        return (
            <>
                <div 
                    className={targetClass} 
                    data-pr-tooltip={`${rowData.isActive ? "" : "Not "}Active`} 
                    data-pr-position="left" 
                    style={{ 
                        display: 'inline-block', 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: rowData.isActive ? '#90EE90' : '#FFCCCB' 
                    }}
                >
                </div>
                <Tooltip target={`.${targetClass}`} />
            </>
        );
    };

    const headerTemplate = () => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ToggleButton
                onLabel="Show Week"
                offLabel="Show Month"
                checked={toggleMonthWeek}
                onChange={(e) => setToggleMonthWeek(e.value)}
                style={{
                    backgroundColor: "#537dbf",
                    width: toggleMonthWeek ? "6%" : "10%",
                    minWidth: "110px"
                }}
            />
            <h3 style={{ flex: 1, textAlign: 'center', margin:"0px",marginRight: "230px" }}>
                Sales/Transaction Entry Compliance
            </h3>
        </div>
    );

    return (
        <div id="TenantDashboard">
            {currentTenant && (
                <Tenants goBack={() => setCurrentTenant(null)} tenant={currentTenant} />
            )}
            {!currentTenant && (
                <>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px", alignItems: "center" }}>
                        <h1 style={{ flex: 1, textAlign: "center", margin: 0 }}>Tenant Management</h1>
                        <Button
                            style={{ marginLeft: "auto" }}
                            label="Add Tenant"
                            onClick={() => setCurrentTenant("new")}
                        />
                    </div>
                    <div id={toggleMonthWeek ? "showMonth" : "showWeek"} className="tenant-table">
                        <DataTable value={tableData}
                            header={headerTemplate}
                            filterDisplay="row"
                            sortField="name"
                            sortOrder="1"
                            showGridlines
                            style={{ maxHeight: scrollHeight }} // Use the dynamic scroll height
                            scrollable
                            scrollHeight={scrollHeight} // Use the dynamic scroll height
                        >
                            <Column field="name"
                                header="Tenant Name"
                                body={nameTemplate}
                                alignHeader='center'
                                className="name"
                                filterPlaceholder="Search tenant"
                                filterMatchMode="contains"
                                showFilterMenu={false}
                                filter
                                sortable
                                style={{ minWidth: "135px" }}
                            />
                            <Column header="Active"
                                body={activeTemplate}
                                field="isActive"
                                alignHeader='center'
                                style={{minWidth:"40px", textAlign:"center"}}
                            />
                            {columnHeaders.length > 0 && columnHeaders.map((ch, idx) => (
                                <Column key={idx}
                                    header={ch}
                                    body={(d) => dayBodyTemplate(d, ch)}
                                    alignHeader="center"
                                />
                            ))}
                        </DataTable>
                    </div>
                </>
            )}
        </div>
    );
};
