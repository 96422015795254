import React, { useState, useContext, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { UserContext } from "../UserContext";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const Home = () => {
    const [transactionData, setTransactionsData] = useState([]);
    const [transactionDates, setTransactionDates] = useState([]);
    const [categorySum, setCategorySum] = useState([]);
    const [categoryLabels, setCategoryLabels] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [monetarySum, setMonetarySum] = useState([]);
    const [transactionSum, setTransactionSum] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [currentMonthCompliance, setCurrentMonthCompliance] = useState([]);
    const [lastThirtyDaysCompliance, setLastThirtyDaysCompliance] = useState([]);
    const user = useContext(UserContext);

    // Refs for the data table containers
    const complianceThisMonthRef = useRef(null);
    const complianceLastThirtyDaysRef = useRef(null);

    // Calculate dynamic height for DataTables
    const [complianceThisMonthHeight, setComplianceThisMonthHeight] = useState('250px');
    const [complianceLastThirtyDaysHeight, setComplianceLastThirtyDaysHeight] = useState('250px');

    useEffect(() => {
        const handleResize = () => {
            if (complianceThisMonthRef.current && complianceLastThirtyDaysRef.current) {
                const complianceThisMonthHeight = complianceThisMonthRef.current.offsetHeight - 53; // Adjust based on your layout
                const complianceLastThirtyDaysHeight = complianceLastThirtyDaysRef.current.offsetHeight - 53; // Adjust based on your layout

                setComplianceThisMonthHeight(`${complianceThisMonthHeight}px`);
                setComplianceLastThirtyDaysHeight(`${complianceLastThirtyDaysHeight}px`);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Options for the bar chart
    const barChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return tooltipItem.dataset.label + ': $' + tooltipItem.raw;
                    }
                }
            },
            title: {
                display: true,
                text: 'Current Month Sales Entered by Category',
                color: '#000',
                font: {
                    size: 14
                },
                padding: {
                    top: 5,
                    bottom: 15
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return '$ ' + value;
                    }
                },
                title: {
                    display: true,
                    color: '#000',
                    font: {
                        size: 12
                    },
                    padding: {
                        top: 5,
                        bottom: 0
                    }
                }
            }
        }
    };

    // Options for the line chart
    const lineChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return tooltipItem.dataset.label + ': $' + tooltipItem.raw;
                    }
                }
            },
            title: {
                display: true,
                text: 'Current Month Sales Entered',
                color: '#000',
                font: {
                    size: 14
                },
                padding: {
                    top: 5,
                    bottom: 15
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return '$ ' + value;
                    }
                },
                title: {
                    display: true,
                    color: '#000',
                    font: {
                        size: 12
                    },
                    padding: {
                        top: 5,
                        bottom: 0
                    }
                }
            }
        }
    };

    useEffect(() => {
        const categoryData = {
            labels: categoryLabels,
            datasets: [
                {
                    label: 'Sales',
                    data: categorySum,
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(153, 102, 255, 0.2)'
                    ],
                    borderColor: [
                        'rgb(255, 159, 64)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgba(255, 99, 132)',
                        'rgb(153, 102, 255)'
                    ],
                    borderWidth: 1
                }
            ]
        };

        const salesData = {
            labels: transactionDates,
            datasets: [
                {
                    label: 'Sales',
                    data: monetarySum,
                    fill: false,
                    borderColor: '#42A5F5',
                    tension: 0.4
                }
            ]
        };

        setCategoryData(categoryData);
        setTransactionsData(transactionData);
        setSalesData(salesData);
    }, [monetarySum, transactionSum, transactionDates, categorySum]);

    useEffect(() => {
        user.apiCall(`getsalesandtransactions/currentmonth`)
            .then(res => {
                const salesData = res.data.salesList;
                const categoryData = res.data.categorySumList;
                const transactionDates = salesData.map(item => item.transactionDate);
                const monetarySum = salesData.map(item => item.monetarySum);
                const transactionSum = salesData.map(item => item.transactionSum);
                const categorySum = categoryData.map(item => item.sum);
                const categoryLabels = categoryData.map(item => item.category);
                const formattedTransactionDates = transactionDates.map(date => {
                    const zonedDate = utcToZonedTime(date, 'America/New_York');
                    return format(zonedDate, 'MM/dd', 'America/New_York');
                });
                //console.log(categoryData)
                setCategorySum(categorySum);
                setCategoryLabels(categoryLabels);
                setTransactionDates(formattedTransactionDates);
                setMonetarySum(monetarySum);
                setTransactionSum(transactionSum);
            });

        user.apiCall('getconcessioncompliance')
            .then(res => {
                setLastThirtyDaysCompliance(res.data);
            });

        user.apiCall('getconcessioncompliance/currentMonth')
            .then(res => {
                setCurrentMonthCompliance(res.data);
            });
    }, [user]);

    return (
        <div id="Home">
            <div className="card" style={{ maxHeight: "90vh", minHeight:"750px"}}>
                <div style={{ textAlign: "center", marginBottom: '40px' }}>
                    <h1>Concessions Sales Entry Compliance</h1>
                </div>
                <div id="SalesAndTransactions" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: '1%' }}>
                        <div id="ComplianceThisMonth" ref={complianceThisMonthRef} style={{ flex: '1', textAlign: 'center' }}>
                            <h2>Compliance Current Month</h2>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10%' }}>
                                <div style={{ fontWeight: "bold", fontSize: "2rem", textAlign: "center", border:"1px solid black", padding:"3px", minHeight:"250px",display:"flex", alignItems:"center", justifyContent:"center", width:"45%", backgroundColor:"#efefef" }}>
                                    {currentMonthCompliance.compliancePercentage}% Compliance
                                </div>
                                <div>
                                    <DataTable 
                                        value={currentMonthCompliance.data} 
                                        header="Top 5 Out of Compliance" 
                                        style={{ width: '100%', minWidth:"270px" }} 
                                        scrollable 
                                        scrollHeight={complianceThisMonthHeight}
                                    >
                                        <Column header="Tenant" field="tenantName" />
                                        <Column header="Missing Inputs" field="missingEntryCount" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '2', minWidth: '300px' }}>
                            <Chart type="bar" data={categoryData} options={barChartOptions} style={{ width: '100%', height: '300px' }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: '1%', marginTop: "1.5%" }}>
                        <div id="ComplianceLastThirtyDays" ref={complianceLastThirtyDaysRef} style={{ flex: '1', textAlign: 'center' }}>
                            <h2>Compliance Last 30 days</h2>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10%' }}>
                                <div style={{ fontWeight: "bold", fontSize: "2rem", textAlign: "center",border:"1px solid black", padding:"3px", minHeight:"250px",display:"flex", alignItems:"center", justifyContent:"center", width:"45%", backgroundColor:"#efefef"}}>
                                    {lastThirtyDaysCompliance.compliancePercentage}% Compliance
                                </div>
                                <div>
                                    <DataTable 
                                        value={lastThirtyDaysCompliance.data} 
                                        header="Top 5 Out of Compliance" 
                                        style={{ width: '100%', minWidth:"270px" }} 
                                        scrollable                                     
                                        scrollHeight={complianceLastThirtyDaysHeight}
                                    >
                                        <Column header="Tenant" field="tenantName" />
                                        <Column header="Missing Inputs" field="missingEntryCount" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '2', minWidth: '300px' }}>
                            <Chart type="line" data={salesData} options={lineChartOptions} style={{ width: '100%', height: '300px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
