
import AppSettings from "../AppSettings.json";

export const msalConfig = {
	auth: {
		clientId: "bacf2f6f-fd78-4eea-b7e1-06ce999101db",
		authority: "https://concessionstest.b2clogin.com/concessionstest.onmicrosoft.com/B2C_1_testSignUpSignIn",
		knownAuthorities: ["concessionstest.b2clogin.com"],
		redirectUri: AppSettings.AUTH_REDIRECT
		
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	}
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ["openid"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
